var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import MuiSlider from '@mui/material/Slider';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ContainerColumn, ContainerRow } from '@src/components/styled';
import { ActiveText } from '@src/components/styled/Typography';
import { debounceWrapper } from '@shared/lib/async-utils';
import Button from '@shared/ui/buttons/Button';
import { ReactComponent as XMarkIcon } from '@icons/wolfkit-light/xmark-circle-light.svg';
import Input from '@shared/ui/inputs/Input';
const Container = styled(ContainerColumn)(() => ({
    height: 'auto',
}));
const SliderInputContainer = styled(ContainerColumn)(() => ({
    position: 'relative',
    height: 'auto',
}));
const SliderStyled = styled(MuiSlider)(props => ({
    position: 'absolute',
    bottom: '-6px',
    borderRadius: '0 0 0 6px',
    padding: `${props.theme.spacing_sizes.s}px 0`,
    '& .MuiSlider-rail': {
        display: 'none',
    },
    '& .MuiSlider-thumb': {
        width: props.theme.spacing_sizes.xs * 2,
        height: props.theme.spacing_sizes.xs * 2,
        boxShadow: 'none',
        '&:before': {
            display: 'none',
        },
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxSizing: 'content-box',
            border: '3px solid #D8E0F8',
            boxShadow: 'none',
        },
    },
    '& .MuiSlider-valueLabel': {
        '&:before': {
            display: 'none',
        },
        background: 'black',
        padding: `${props.theme.spacing_sizes.s / 2}px ${props.theme.spacing_sizes.s}px`,
        borderRadius: props.theme.spacing_sizes.s,
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(0%, -70%)',
            '&:before': {
                display: 'none',
            },
        },
    },
}));
const MarksContainer = styled(ContainerRow)(props => ({
    height: 'auto',
    justifyContent: 'space-between',
    marginTop: props.theme.spacing_sizes.xs,
}));
const Mark = styled(ActiveText)(props => ({
    fontWeight: 400,
    color: props.theme.palette.text.secondary,
}));
const ValidateInputDebounceDelay = 500;
const SliderInput = (_a) => {
    var { value, onChange, minMaxValues, buttonAction = 'reset' } = _a, props = __rest(_a, ["value", "onChange", "minMaxValues", "buttonAction"]);
    const { t } = useTranslation();
    const validateInput = useCallback((v) => {
        const [min, max] = minMaxValues;
        let newValue = v;
        if (newValue < min) {
            newValue = min;
        }
        else if (newValue > max) {
            newValue = max;
        }
        if (v !== newValue) {
            onChange(newValue);
        }
    }, [minMaxValues, onChange]);
    const validateInputDebounce = useMemo(() => debounceWrapper(validateInput, ValidateInputDebounceDelay), [validateInput]);
    const onChangeHandler = useCallback((newValue) => {
        const formattedValue = Number(newValue) || 0;
        onChange(formattedValue);
    }, [onChange]);
    const onInputChange = (ev) => {
        const formattedValue = Number(ev.target.value) || 0;
        onChangeHandler(ev.target.value);
        validateInputDebounce(formattedValue);
    };
    const onSliderChange = (event, newValue) => {
        if (typeof newValue === 'number') {
            onChangeHandler(`${newValue}`);
        }
    };
    const handleAdornmentClick = useCallback(() => {
        if (buttonAction === 'reset') {
            onChangeHandler(`${minMaxValues[0]}`);
            validateInputDebounce(minMaxValues[0]);
        }
        if (buttonAction === 'select_all') {
            onChangeHandler(`${minMaxValues[1]}`);
            validateInputDebounce(minMaxValues[1]);
        }
    }, [onChangeHandler, validateInputDebounce, buttonAction, minMaxValues]);
    const EndAdornmentButton = useMemo(() => {
        if (buttonAction === 'reset' && value !== minMaxValues[0]) {
            return (_jsx(Button, { type: 'button', variant: 'plain', startIcon: XMarkIcon, startIconProps: { size: 20, keepOriginalColor: true }, onClick: handleAdornmentClick, style: {
                    minHeight: 'unset',
                    minWidth: 'unset',
                    padding: 0,
                } }));
        }
        if (value !== minMaxValues[1]) {
            return (_jsx(Button, { type: 'button', variant: 'plain', onClick: handleAdornmentClick, children: t('actions.all', { ns: 'common' }) }));
        }
        return null;
    }, [buttonAction, value, minMaxValues, handleAdornmentClick, t]);
    return (_jsxs(Container, { children: [_jsxs(SliderInputContainer, { children: [_jsx(Input
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    , Object.assign({}, props, { value: value, onChange: onInputChange, endAdornment: EndAdornmentButton })), _jsx(SliderStyled, { size: 'medium', min: minMaxValues[0], max: minMaxValues[1], value: value, onChange: onSliderChange, valueLabelDisplay: 'auto' })] }), _jsxs(MarksContainer, { children: [_jsx(Mark, { children: minMaxValues[0] }), _jsx(Mark, { children: minMaxValues[1] })] })] }));
};
export default SliderInput;
